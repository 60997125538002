import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Footer from "./Footer";
import { playersService } from "../../services";
import SideBar from "./SideBar";
import { useDispatch, useSelector } from "react-redux";
import { playingStatus } from "../../reducers/players/playersSlice";
import { Box, Button, IconButton, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import SwipeIcon from "@mui/icons-material/Swipe";
import "./space-object.css";
import { KeyboardBackspace, KeyboardReturn } from "@mui/icons-material";
import backgroundLight from "../../assets/adsPlayerBG.png";

const PREFIX = "SpaceObject";

const classes = {
  spaceObjectContainer: `${PREFIX}-spaceObjectContainer`,
  image: `${PREFIX}-image`,
  title: `${PREFIX}-title`,
  elementDescriptiontt: `${PREFIX}-elementDescriptiontt`,
  elementDescriptionin: `${PREFIX}-elementDescriptionin`,
  iconButton: `${PREFIX}-iconButton`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.spaceObjectContainer}`]: {
    position: "relative",
    width: "100%",
    height: "100%",
    scrollSnapAlign: "start",
    zIndex: "90",
    backgroundImage: `url(${backgroundLight})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },

  [`& .${classes.image}`]: {
    backgroundColor: "black",
    height: "100%",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      objectFit: "contain",
    },
    objectFit: "contain",
  },

  [`& .${classes.title}`]: {
    textAlign: "center",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "90%",
    position: "absolute",
    top: "0",
    left: "50%",
    transform: "translateX(-50%)",
  },
  [`& .${classes.elementDescriptiontt}`]: {
    width: "95%",
    color: "white",
    position: "absolute",
    bottom: "10px",
    left: "50%",
    transform: "translateX(-50%)",
    textAlign: "center",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxHeight: "32px",
    zIndex: "15",
  },
  [`& .${classes.elementDescriptionin}`]: {
    width: "73%",
    color: "white",
    position: "absolute",
    bottom: "4rem",
    left: "50%",
    transform: "translateX(-50%)",
    textAlign: "center",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxHeight: "32px",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
  [`& .${classes.iconButton}`]: {
    marginTop: "0.5rem",
    color: "#343434",
    backgroundColor: "rgba(211, 175, 95, 0.8)",
    zIndex: "10",
    [theme.breakpoints.between("xs", "md")]: {},
    "&:hover": {
      color: "#343434",
      backgroundColor: "rgba(211, 175, 95, 0.8)",
    },
  },
}));

const SpaceObject = ({
  url,
  usdz = null,
  token,
  verticalPlayer,
  isActive,
  myVideoTag,
  currentIndex,
  isFrame = false,
  molding,
  size = "medium",
  imageUrl,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentElement = useSelector(
    (state) => state.players.playlist[state.players.currentPlayingIndex]
  );
  const firstTime = useSelector((state) => state.players.firstTime);
  const [once, setOnce] = useState(false);
  const [modelViewerControls, setModelViewerControls] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);
  const [frameModelUrl, setFrameModelUrl] = useState("");
  const [isTextureApplied, setIsTextureApplied] = useState(false);

  // Use a unique ID for each model-viewer
  const modelViewerId = `model-viewer-${currentIndex}`;

  const applyTextureToModel = useCallback(async () => {
    if (isFrame && imageUrl) {
      const img = new Image();
      img.onload = async () => {
        const imgWidth = img.width;
        const imgHeight = img.height;
        const landscape = imgWidth > imgHeight;
        let imageDimensions = { x: 1, y: 1, z: 1 };
        setIsLandscape(landscape);

        // Calculate dimensions based on image aspect ratio
        if (imgWidth === imgHeight) {
          imageDimensions = { x: 1.34, y: 1, z: 1 };
        } else if (landscape) {
          imageDimensions = { x: imgWidth / imgHeight, y: 1.34, z: 1 };
        } else {
          imageDimensions = { x: 1, y: imgHeight / imgWidth / 1.34, z: 1 };
        }

        setIsImageLoaded(true);
        const modelViewer = document.getElementById(modelViewerId);
        if (!modelViewer) return;

        // Apply dimensions to the model
        modelViewer.scale = `${imageDimensions.x} ${imageDimensions.y} ${imageDimensions.z}`;

        // Find the material to apply texture to
        const painting = modelViewer.model?.materials.filter((material) =>
          material.name.includes("content")
        )[0];

        if (painting) {
          // Set alpha mode for transparency
          painting.setAlphaMode("BLEND");

          // Create texture from image URL
          const texture = await modelViewer.createTexture(imageUrl);

          // Apply texture to the base color channel
          await painting.pbrMetallicRoughness.baseColorTexture.setTexture(
            texture
          );

          // Set state to show the model
          setIsTextureApplied(true);
        } else {
          console.error("Material not found or does not support textures.");
        }
      };
      img.src = imageUrl;
    } else {
      // For non-frame models, show immediately
      setIsTextureApplied(true);
    }
  }, [imageUrl, modelViewerId, isFrame]);

  // Generate frame model URL
  useEffect(() => {
    if (isFrame && molding) {
      const landscapeStr = isLandscape ? "-landscape" : "";
      const sizeStr =
        molding === "none"
          ? ""
          : `-${size === "custom" ? "large" : !size ? "medium" : size}`;
      const modelUrl = `https://ar-prod.s3.fr-par.scw.cloud/0_FRAMES_AR/${molding}${landscapeStr}${sizeStr}.glb`;
      setFrameModelUrl(modelUrl);
    }
  }, [isFrame, molding, size, isImageLoaded, isLandscape]);

  useEffect(() => {
    if (isActive && !firstTime) dispatch(playingStatus(true));
    if (isActive && myVideoTag) myVideoTag.pause();
  }, [isActive, firstTime, dispatch, myVideoTag]);

  useEffect(() => {
    if (isActive) {
      if (!once) {
        playersService.playerContentSeen(token, currentElement.id);
      }
      setOnce(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  useEffect(() => {
    // Only manipulate the model-viewer if it's active
    if (isActive) {
      const modelViewer = document.getElementById(modelViewerId);

      if (modelViewer) {
        // Configure camera controls
        modelViewer.cameraControls = modelViewerControls;

        // Configure auto-rotation (enabled when controls are disabled)
        modelViewer.autoRotate = !modelViewerControls;

        // Define the handler function
        const handleLoad = () => {
          if (isFrame) {
            applyTextureToModel();
          } else {
            // If it's not a frame model, show it immediately
            setIsTextureApplied(true);
          }
        };

        // Store the handler on the element for cleanup
        modelViewer._handleLoadFunction = handleLoad;

        // Remove any existing listener first
        modelViewer.removeEventListener("load", handleLoad);
        modelViewer.addEventListener("load", handleLoad);

        // Check if model is already loaded and manually trigger the handler
        if (modelViewer.loaded) {
          handleLoad();
        }
      }

      // Enable/disable swipe based on controls state
      const swiperInstance = document.querySelector(".swiper")?.swiper;
      if (swiperInstance) {
        if (modelViewerControls) {
          // Disable swipe when controls are activated
          swiperInstance.allowTouchMove = false;
        } else {
          // Re-enable swipe when controls are deactivated
          swiperInstance.allowTouchMove = true;
        }
      }
    }

    return () => {
      const modelViewer = document.getElementById(modelViewerId);
      if (modelViewer && modelViewer._handleLoadFunction) {
        modelViewer.removeEventListener(
          "load",
          modelViewer._handleLoadFunction
        );
        modelViewer._handleLoadFunction = null;
      }
    };
  }, [
    modelViewerControls,
    isActive,
    modelViewerId,
    isFrame,
    applyTextureToModel,
  ]);

  // Reset controls state when the slide changes
  useEffect(() => {
    if (!isActive && modelViewerControls) {
      setModelViewerControls(false);
    }
  }, [isActive, modelViewerControls]);

  return (
    <Root className={classes.spaceObjectContainer}>
      <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
        {/* Custom loader that shows only when texture is not yet applied */}
        {!isTextureApplied && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 10,
              color: "white",
            }}
          >
            <Box
              sx={{
                width: 40,
                height: 40,
                borderRadius: "50%",
                border: "3px solid rgba(255, 255, 255, 0.3)",
                borderTop: "3px solid white",
                animation: "spin 1s linear infinite",
                mb: 2,
              }}
            />
            <Box sx={{ fontSize: "1.2rem" }}>
              {isFrame ? t("player.preparingFrame") : t("player.loadingModel")}
            </Box>
          </Box>
        )}

        <model-viewer
          src={isFrame ? frameModelUrl : url}
          ios-src={usdz}
          alt="3D Model viewer"
          auto-rotate
          autoplay
          camera-controls={false}
          ar
          ar-modes="webxr scene-viewer quick-look"
          ar-scale="auto"
          ar-placement={isFrame ? "wall" : "floor"}
          min-field-of-view="70deg"
          max-field-of-view="85deg"
          style={{
            width: "100%",
            height: "100%",
            "--ar-button-z-index": "9999",
            opacity: isTextureApplied ? 1 : 0,
            transition: "opacity 0.3s ease-in",
          }}
          id={modelViewerId}
          loading="eager"
          reveal="auto"
        >
          {/* Existing poster slot for model-viewer's native loader */}
          <div
            slot="poster"
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              fontSize: "1.5rem",
            }}
          >
            {t("player.loadingModel")}
          </div>
        </model-viewer>
        {!isActive ? null : (
          <Stack
            direction="row"
            spacing={1}
            sx={{
              position: "absolute",
              bottom: "100px",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 999,
            }}
          >
            {!modelViewerControls ? (
              <IconButton
                variant="contained"
                color="primary"
                size="large"
                className={classes.iconButton}
                onClick={() => {
                  const modelViewer = document.getElementById(modelViewerId);
                  if (modelViewer) {
                    // Enable camera controls
                    modelViewer.cameraControls = true;
                    // Disable auto-rotation
                    modelViewer.autoRotate = false;
                    setModelViewerControls(true);
                  }
                }}
              >
                <SwipeIcon />
              </IconButton>
            ) : (
              <IconButton
                variant="contained"
                color="primary"
                size="large"
                className={classes.iconButton}
                onClick={() => {
                  const modelViewer = document.getElementById(modelViewerId);
                  if (modelViewer) {
                    // Disable camera controls
                    modelViewer.cameraControls = false;
                    // Enable auto-rotation
                    modelViewer.autoRotate = true;
                    setModelViewerControls(false);
                  }
                }}
              >
                <KeyboardReturn />
              </IconButton>
            )}
          </Stack>
        )}
      </Box>
      <Footer verticalPlayer={verticalPlayer} isModelViewer />
      <SideBar token={token} />
    </Root>
  );
};
export default SpaceObject;
