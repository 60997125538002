import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  styled,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
// import { webChatSocket } from "../../socket";
import { useSnackbar } from "notistack";
import { TypeAnimation } from "react-type-animation";
import { webChatSocket } from "../../socket";

const PREFIX = "AIDialog";

const classes = {
  messagesContainer: `${PREFIX}-messagesContainer`,
  AIResponseContainer: `${PREFIX}-AIResponseContainer`,
  userTextContainer: `${PREFIX}-userTextContainer`,
};

const AIDialogContainer = styled("div")(({ theme }) => ({
  [`& .${classes.messagesContainer}`]: {
    height: "60vh",
    width: "70vw",
    maxWidth: "350px",
  },

  [`& .${classes.AIResponseContainer}`]: {
    height: "79%",
    overflowY: "auto",
    padding: "0.5rem",
    "&::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "rgb(181 154 79 / 58%)",
      borderRadius: "10px",
    },
  },

  [`& .${classes.userTextContainer}`]: {
    marginTop: "1rem",
    height: "160px",
  },
}));

const AIDialog = ({ open, onClose, configId, introductionAI }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [message, setMessage] = useState("");
  const [answers, setAnswers] = useState(null);
  const [loading, setLoading] = useState(false);
  const tmpAnswers = useRef([]);

  const handleSendText = () => {
    setAnswers([...answers, message]);
    tmpAnswers.current = [...answers, message];
    setMessage("");
    setLoading(true);
    webChatSocket.emit("message", { configId, message }, () =>
      enqueueSnackbar(t("toasts.socketTimeout"), { variant: "error" })
    );
  };

  const scrollToBottom = useCallback(() => {
    const element = document.getElementById(`answersContainer-${configId}`);
    if (element && open)
      element.scrollTop = element.scrollHeight - element.clientHeight;
  }, [configId, open]);

  useEffect(() => {
    if (answers?.length > 0) scrollToBottom();
  }, [answers, scrollToBottom]);

  const handleAnswers = (res) => {
    setAnswers([...tmpAnswers.current, res]);
  };

  useEffect(() => {
    if (open) {
      setAnswers(introductionAI ? [introductionAI] : []);
      tmpAnswers.current = [];
      setLoading(false);
      setMessage("");
      webChatSocket.connect();
      webChatSocket.on("answer", (res) => {
        handleAnswers(res);
        setLoading(false);
      });
    }

    return () => {
      webChatSocket.off("answer");
      webChatSocket.disconnect();
    };
  }, [open, introductionAI]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableEscapeKeyDown
      aria-labelledby="ai-dialog-webxr"
      aria-describedby="ai-dialog-webxr-desc"
    >
      <AIDialogContainer style={{ overflow: "hidden" }}>
        <DialogTitle sx={{ paddingBottom: "0" }}>
          {t("player.buttons.letstalk")}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: "absolute",
            right: 10,
            top: 10,
            color: theme.palette.grey[500],
          })}
        >
          <Close />
        </IconButton>
        <DialogContent sx={{ overflow: "unset" }}>
          <Box className={classes.messagesContainer}>
            <Paper
              elevation={5}
              className={classes.AIResponseContainer}
              id={`answersContainer-${configId}`}
            >
              {answers?.map((item, index) => (
                <Box
                  key={`answer-${index + 1}-${configId}`}
                  sx={{ display: "flex" }}
                >
                  {index % 2 === 1 && <Box sx={{ width: "45%" }} />}
                  <Paper
                    elevation={12}
                    sx={{
                      width: "200px",
                      padding: "0.5rem",
                      marginBottom: "0.75rem",
                    }}
                  >
                    <TypeAnimation
                      splitter={(str) => str.split(/(?= )/)} // split by words
                      sequence={[item, () => scrollToBottom()]}
                      wrapper="p"
                      key={item}
                      speed={{ type: "keyStrokeDelayInMs", value: 10 }}
                      omitDeletionAnimation={true}
                      style={{
                        fontSize: "16px",
                        display: "block",
                        fontFamily: "Lato, sans-serif",
                        color: "#ffffffb3",
                        margin: 0,
                        whiteSpace: "pre-line",
                      }}
                      cursor={false}
                      repeat={0}
                    >
                      {item}
                    </TypeAnimation>
                  </Paper>
                  {index % 2 === 0 && <Box sx={{ width: "45%" }} />}
                </Box>
              ))}
            </Paper>
            <Box className={classes.userTextContainer}>
              <TextField
                sx={{ width: "100%" }}
                value={message}
                multiline
                rows={2}
                placeholder={t("player.buttons.enterAIMessage")}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSendText}
            disabled={message.length <= 0 || loading}
          >
            {loading ? (
              <CircularProgress size={28} />
            ) : (
              t("player.buttons.sendButton")
            )}
          </Button>
        </DialogActions>
      </AIDialogContainer>
    </Dialog>
  );
};

export default AIDialog;
