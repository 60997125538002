import { io } from "socket.io-client";

export const webChatSocket = io(
  `${process.env.REACT_APP_SOCKET_ROUTE}/public/chat`,
  {
    reconnectionAttempts: 5,
    transports: ["websocket"],
    path: "/ws",
    autoConnect: false,
  }
);
