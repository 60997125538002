import React from "react";
import styled from "@emotion/styled";
import {
  Box,
  Button,
  Divider,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const PREFIX = "CustomIconsList";

const classes = {
  externalLinksContainer: `${PREFIX}-externalLinksContainer`,
  customWatermarkContainer: `${PREFIX}-customWatermarkContainer`,
  iconSize: `${PREFIX}-iconSize`,
};
const CustomIconsListContainer = styled(Box)(({ theme }) => ({
  [`& .${classes.externalLinksContainer}`]: {
    maxHeight: "400px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "rgb(181 154 79 / 58%)",
      borderRadius: "10px",
    },
  },

  [`& .${classes.customWatermarkContainer}`]: {
    display: "flex",
    justifyContent: "space-between",
    textAlign: "center",
  },

  [`& .${classes.iconSize}`]: {
    width: "90px",
    height: "90px",
    objectFit: "cover",
    objectPosition: "center",
  },
}));

const CustomIconsList = (props) => {
  const { t } = useTranslation();

  return (
    <CustomIconsListContainer>
      <List disablePadding className={classes.externalLinksContainer}>
        {props.config.externalLinks.map((element, index) => (
          <React.Fragment key={`custom-watermark-${index + 1}`}>
            <Link href={element.link} target="_blank" color={"primary"}>
              <ListItem button className={classes.customWatermarkContainer}>
                <ListItemIcon>
                  <img
                    className={classes.iconSize}
                    src={element.logo}
                    alt={`custom-watermark-icon-${index + 1}`}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={element.title}
                  className={classes.externalLinkstextListStyle}
                />
                <Button variant="outlined" color="primary">
                  {t("player.buttons.visitCustomWatermarkButton")}
                </Button>
              </ListItem>
            </Link>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </CustomIconsListContainer>
  );
};

export default CustomIconsList;
