import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Dialog,
  DialogContent,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const PREFIX = "InformationsDialog";

const classes = {
  dialogPadding: `${PREFIX}-dialogPadding`,
  contentPanel: `${PREFIX}-contentPanel`,
  flagContainer: `${PREFIX}-flagContainer`,
  flagStyle: `${PREFIX}-flagStyle`,
  textFontFamily: `${PREFIX}-textFontFamily`,
};

const StyledDiv = styled("div")(({ theme }) => ({
  [`& .${classes.dialogPadding}`]: {
    padding: 0,
  },

  [`& .${classes.contentPanel}`]: {
    minHeight: "100px",
  },

  [`& .${classes.flagContainer}`]: {
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.flagStyle}`]: {
    width: "2rem",
    height: "3rem",
  },

  [`& .${classes.textFontFamily}`]: {
    fontFamily: "Roboto",
  },
}));

const InformationsDialog = (props) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const currentFlag = (code) => {
    return (
      <img
        loading="lazy"
        width="45px"
        src={`https://flagcdn.com/${code.toLowerCase()}.svg`}
        alt=""
      />
    );
  };

  function a11yProps(index) {
    return {
      id: `scrollable-prevent-tab-${index}`,
      "aria-controls": `scrollable-prevent-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-prevent-tabpanel-${index}`}
        aria-labelledby={`scrollable-prevent-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3} className={classes.contentPanel}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }

  return (
    <StyledDiv>
      <Dialog
        onClose={() => props.setIsDescriptionOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={props.isDescriptionOpen}
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: "rgba(17, 17, 17, 0.8)",
            borderRadius: "15px",
          },
        }}
      >
        <DialogContent
          dividers
          className={
            !(props.initialDesc && props.translations?.length === 0)
              ? classes.dialogPadding
              : ""
          }
        >
          <>
            <div>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                variant={
                  props.translations.length > 3 ? "scrollable" : "fullWidth"
                }
                scrollButtons="auto"
                aria-label="scrollable prevent tabs descriptions"
                allowScrollButtonsMobile
              >
                {props.initialDesc && (
                  <Tab
                    icon={<InfoIcon />}
                    aria-label="initial description informations"
                    {...a11yProps(0)}
                    key={`initial-desc-${0}`}
                  />
                )}
                {props.translations.map((trans, index) => {
                  let finalIndex = index;
                  if (props.initialDesc) finalIndex = finalIndex + 1;
                  return (
                    <Tab
                      icon={currentFlag(trans.lang)}
                      aria-label={trans.lang}
                      {...a11yProps(finalIndex)}
                      key={`${trans.lang}-${finalIndex}`}
                    />
                  );
                })}
              </Tabs>
            </div>
            {props.initialDesc && (
              <TabPanel
                value={value}
                index={0}
                key={`initial-description-${0}`}
              >
                <Typography
                  gutterBottom
                  dangerouslySetInnerHTML={{ __html: props.initialDesc }}
                  className={classes.textFontFamily}
                />
              </TabPanel>
            )}
            {props.translations.map((trans, index) => {
              let finalIndex = index;
              if (props.initialDesc) finalIndex = finalIndex + 1;
              return (
                <TabPanel
                  value={value}
                  index={finalIndex}
                  key={`description-${trans.lang}-${finalIndex}`}
                >
                  <Typography
                    gutterBottom
                    dangerouslySetInnerHTML={{ __html: trans.text }}
                    className={classes.textFontFamily}
                  />
                </TabPanel>
              );
            })}
          </>
        </DialogContent>
      </Dialog>
    </StyledDiv>
  );
};

export default InformationsDialog;
