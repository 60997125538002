import React, { useMemo } from "react";
import { styled } from "@mui/material/styles";
import { VolumeMute, VolumeOff, PlayArrow, Pause } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { changeMute, playingStatus } from "../../reducers/players/playersSlice";
import { useDispatch, useSelector } from "react-redux";
import { countdown } from "../../helpers";

const PREFIX = "PlayerFooter";

const classes = {
  horizontalPlayerFooter: `${PREFIX}-horizontalPlayerFooter`,
  verticalPlayerFooter: `${PREFIX}-verticalPlayerFooter`,
  playerFooterText: `${PREFIX}-playerFooterText`,
  playerFooterTicker: `${PREFIX}-playerFooterTicker`,
  playerFooterTickerWithoutAuthor: `${PREFIX}-playerFooterTickerWithoutAuthor`,
  miniAvatar: `${PREFIX}-miniAvatar`,
  miniAvatarDefault: `${PREFIX}-miniAvatarDefault`,
  login: `${PREFIX}-login`,
  loginVertical: `${PREFIX}-loginVertical`,
  description: `${PREFIX}-description`,
  muteIcons: `${PREFIX}-muteIcons`,
  horizontalPlayerButtons: `${PREFIX}-horizontalPlayerButtons`,
  verticalPlayerButtons: `${PREFIX}-verticalPlayerButtons`,
  iconBackground: `${PREFIX}-iconBackground`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.horizontalPlayerFooter}`]: {
    color: "white",
    height: "55px",
    display: "flex",
    position: "absolute",
    top: "0px",
    width: "100%",
    // backgroundColor: "rgba(85, 85, 85, 0.3)",
  },
  [`&.${classes.verticalPlayerFooter}`]: {
    color: "white",
    display: "flex",
    height: "78px",
    position: "absolute",
    bottom: "0",
    width: "100%",
    // backgroundColor: "rgba(85, 85, 85, 0.3)",
    paddingRight: "60px",
    pointerEvents: "none",
  },
  [`& .${classes.playerFooterText}`]: {
    flex: "1",
    fontSize: "12px",
    paddingTop: "0.25rem",
    "& > h3": {
      marginBottom: "0.5rem",
      marginTop: "0",
    },
    "& > p": {
      margin: "0",
    },
  },

  [`& .${classes.playerFooterTicker}`]: {
    fontSize: "11px",
    position: "relative",
    maxWidth: "200px",
    top: "-30px",
    left: "55px",
  },

  [`& .${classes.playerFooterTickerWithoutAuthor}`]: {
    fontSize: "12px",
    position: "relative",
    maxWidth: "200px",
    top: "-35px",
    left: "38px",
  },

  [`& .${classes.miniAvatar}`]: {
    borderRadius: "50%",
    height: "45px",
    width: "45px",
    objectFit: "cover",
  },

  [`& .${classes.miniAvatarDefault}`]: {
    borderRadius: "50%",
    height: "45px",
    width: "45px",
    objectFit: "cover",
    marginRight: "0.5rem",
    color: theme.palette.primary.main,
  },

  [`& .${classes.login}`]: {
    position: "relative",
    top: "-20px",
    marginLeft: "0.25rem",
  },

  [`& .${classes.description}`]: {
    marginTop: "-0.5rem",
  },

  [`& .${classes.iconsColor}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${classes.horizontalPlayerButtons}`]: {
    display: "flex",
    justifyContent: "space-between",
    width: "70px",
    position: "absolute",
    top: "15px",
    right: "20px",
    zIndex: "1200",
  },
  [`& .${classes.verticalPlayerButtons}`]: {
    position: "absolute",
    bottom: "42px",
    right: "28px",
    zIndex: "1200",
  },
  [`& .${classes.iconBackground}`]: {
    backgroundColor: "rgba(85, 85, 85, 0.3)",
    width: "24px",
    height: "24px",
    borderRadius: "50%",
  },
}));

const PlayerFooter = ({ mutable, verticalPlayer, isModelViewer }) => {
  const dispatch = useDispatch();
  const currentElement = useSelector(
    (state) => state.players.playlist[state.players.currentPlayingIndex]
  );

  const muted = useSelector((state) => state.players.muted);
  const isPlaying = useSelector((state) => state.players.playing);

  const date1 = useMemo(() => new Date(), []);
  const date2 = useMemo(
    () => new Date(currentElement.createdDate),
    [currentElement.createdDate]
  );
  const postedAt = useMemo(
    () => countdown(date1.getTime() / 1000 - date2.getTime() / 1000),
    [date1, date2]
  );

  return (
    <Root
      className={
        !verticalPlayer
          ? classes.horizontalPlayerFooter
          : classes.verticalPlayerFooter
      }
      style={{
        backgroundColor: isModelViewer ? "inherit" : "rgba(85, 85, 85, 0.3)",
      }}
    >
      <div className={classes.playerFooterText}>
        {currentElement.pushedBy?.login && (
          <h3>
            <div>
              {!currentElement.pushedBy?.avatar ? (
                <AccountCircleIcon className={classes.miniAvatarDefault} />
              ) : (
                <img
                  src={currentElement.pushedBy?.avatar}
                  alt={`avatar ${currentElement.pushedBy?.login}`}
                  className={classes.miniAvatar}
                />
              )}
              <span className={classes.login}>
                {currentElement.pushedBy?.login}
              </span>
            </div>
          </h3>
        )}
        <div
          className={
            !verticalPlayer
              ? classes.horizontalPlayerButtons
              : classes.verticalPlayerButtons
          }
        >
          {isPlaying && !verticalPlayer ? (
            <Pause
              className={classes.iconBackground}
              color="primary"
              onClick={() => dispatch(playingStatus(false))}
            />
          ) : !isPlaying && !verticalPlayer ? (
            <PlayArrow
              className={classes.iconBackground}
              color="primary"
              onClick={() => dispatch(playingStatus(true))}
            />
          ) : null}
          {mutable && muted ? (
            <VolumeOff
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(changeMute());
              }}
              className={classes.iconBackground}
            />
          ) : mutable && !muted ? (
            <VolumeMute
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(changeMute());
              }}
              className={classes.iconBackground}
            />
          ) : null}
        </div>
        <div
          className={
            currentElement.pushedBy
              ? classes.playerFooterTicker
              : classes.playerFooterTickerWithoutAuthor
          }
        >
          {currentElement.pushedBy?.login ? postedAt : ""}
        </div>
      </div>
    </Root>
  );
};

export default PlayerFooter;
