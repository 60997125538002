import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MessageIcon from "@mui/icons-material/Message";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Box, Drawer, IconButton, Link } from "@mui/material";
import TextsmsIcon from "@mui/icons-material/Textsms";
import {
  messageHasBeenRead,
  openModal,
  playingStatus,
} from "../../reducers/players/playersSlice";
import {
  likeContent,
  unlikeContent,
} from "../../reducers/players/playersThunks";
import LaunchIcon from "@mui/icons-material/Launch";
import ShareIcon from "@mui/icons-material/Share";
import InfoIcon from "@mui/icons-material/Info";
import HelpIcon from "@mui/icons-material/Help";
import CustomIconsList from "./CustomIconsList";
import { Assistant } from "@mui/icons-material";
import AIDialog from "./AIDialog";
import InformationsDialog from "./InformationsDialog";

const PREFIX = "SideBar";

const classes = {
  videoSidebar: `${PREFIX}-videoSidebar`,
  videoSidebarButtonLike: `${PREFIX}-videoSidebarButtonLike`,
  videoSidebarButtonMessage: `${PREFIX}-videoSidebarButtonMessage`,
  dialogTitle: `${PREFIX}-dialogTitle`,
  closeIconButton: `${PREFIX}-closeIconButton`,
  iconButton: `${PREFIX}-iconButton`,
  maskRedirectionBarContainer: `${PREFIX}-maskRedirectionBarContainer`,
  textfieldStyle: `${PREFIX}-textfieldStyle`,
  iconBackground: `${PREFIX}-iconBackground`,
  likeCounter: `${PREFIX}-likeCounter`,
  iconEventsBlocker: `${PREFIX}-iconEventsBlocker`,
  customIconRapidAccess: `${PREFIX}-customIconRapidAccess`,
};

const SideBarContainer = styled("div")(({ theme }) => ({
  [`&.${classes.videoSidebar}`]: {
    position: "absolute",
    bottom: "200px",
    right: "0",
    color: "white",
    zIndex: "1200",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  [`& .${classes.videoSidebarButtonLike}`]: {
    padding: "15px",
    textAlign: "center",
    position: "absolute",
    bottom: "100px",
    right: "0px",
  },
  [`& .${classes.videoSidebarButtonMessage}`]: {
    // padding: "5px 15px",
    paddingRight: "15px",
    textAlign: "center",
  },
  [`& .${classes.dialogTitle}`]: {
    display: "flex",
    justifyContent: "space-between",
    height: "2rem",
    padding: "1rem",
  },

  [`& .${classes.closeIconButton}`]: {
    paddingRight: "0",
    transform: "translateY(-2px)",
  },

  [`& .${classes.textfieldStyle}`]: {
    marginTop: "1rem",
    width: "100%",
  },
  [`& .${classes.iconBackground}`]: {
    backgroundColor: "rgba(85, 85, 85, 0.3)",
    width: "48px",
    height: "48px",
    borderRadius: "50%",
  },
  [`& .${classes.iconEventsBlocker}`]: {
    pointerEvents: "none",
  },
  [`& .${classes.likeCounter}`]: {
    position: "absolute",
    top: "17px",
    right: "28px",
  },

  [`& .${classes.iconButton}`]: {
    marginTop: "0.5rem",
    color: "#343434",
    backgroundColor: "rgba(211, 175, 95, 0.8)",
    zIndex: "10",
    [theme.breakpoints.between("xs", "md")]: {},
    "&:hover": {
      color: "#343434",
      backgroundColor: "rgba(211, 175, 95, 0.8)",
    },
  },

  [`& .${classes.maskRedirectionBarContainer}`]: {
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.customIconRapidAccess}`]: {
    marginTop: "0.5rem",
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    objectFit: "cover",
    objectPosition: "center",
  },
}));

const SideBar = ({ token }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [requestLogin, setRequestLogin] = useState(false);
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [AIDialogOpen, setAIDialogOpen] = useState(false);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);

  const loggedIn = useSelector((state) => state.user.currentUser);
  const currentElement = useSelector(
    (state) => state.players.playlist[state.players.currentPlayingIndex]
  );
  const currentIndex = useSelector(
    (state) => state.players.currentPlayingIndex
  );
  const messageRead = useSelector(
    (state) => state.players.playlist[state.players.currentPlayingIndex].read
  );
  const prevModalLogin = useRef();

  const callLikeUnlike = async () => {
    if (!currentElement.like) {
      try {
        const thunk = await dispatch(
          likeContent({
            iv: token.iv,
            content: token.content,
            id: currentElement.id,
            storyIndex: currentIndex,
          })
        );
        if (thunk.meta.requestStatus === "rejected") throw new Error("offline");
      } catch {
        enqueueSnackbar(t("toasts.likeError"), { variant: "error" });
      }
    } else {
      try {
        const thunk = await dispatch(
          unlikeContent({
            iv: token.iv,
            content: token.content,
            id: currentElement.id,
            storyIndex: currentIndex,
          })
        );
        if (thunk.meta.requestStatus === "rejected") throw new Error("offline");
      } catch {
        enqueueSnackbar(t("toasts.dislikeError"), { variant: "error" });
      }
    }
  };

  useEffect(() => {
    if (prevModalLogin.current !== loggedIn && requestLogin) {
      setRequestLogin(false);
      callLikeUnlike();
    }
    prevModalLogin.current = loggedIn;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  const handleLike = () => {
    if (loggedIn) {
      callLikeUnlike();
    } else {
      setRequestLogin(true);
      if (currentElement.playing) dispatch(playingStatus());
      dispatch(openModal("auth"));
    }
  };

  const handleMessage = () => {
    if (loggedIn) {
      dispatch(openModal("message"));
    } else {
      dispatch(openModal("auth"));
    }
  };

  const isAnyRapidAccessToFalse = (currentElement) => {
    return currentElement.externalLinks.some((item) => !item.rapidAccess);
  };

  const shareMedia = () => {
    navigator.share({
      title: document.title,
      text: `${t("player.buttons.shareExparienceStart")}${
        currentElement.label ||
        currentElement.displayName ||
        currentElement.name
      }${t("player.buttons.shareExparienceEnd")}`,
      url: currentElement.shortLink,
    });
  };

  return (
    <SideBarContainer className={classes.videoSidebar}>
      {currentElement.model3D && (
        <AIDialog
          open={AIDialogOpen}
          onClose={(e, reason) => {
            if (reason && reason === "backdropClick") return;
            setAIDialogOpen(false);
          }}
          configId={currentElement.model3D._id}
          introductionAI={currentElement.model3D.introductionAI}
          id={currentElement.model3D._id}
        />
      )}
      <InformationsDialog
        initialDesc={currentElement?.description?.initial || ""}
        translations={currentElement?.description?.translations || []}
        isDescriptionOpen={isDescriptionOpen}
        setIsDescriptionOpen={setIsDescriptionOpen}
      />
      {currentElement.like ? (
        <Box className={classes.videoSidebarButtonMessage}>
          <IconButton
            disableRipple
            className={classes.iconButton}
            size="large"
            aria-label="Like"
            onClick={(e) => {
              e.stopPropagation();
              handleLike();
            }}
          >
            <FavoriteIcon className={classes.iconEventsBlocker} />
          </IconButton>
        </Box>
      ) : (
        <Box className={classes.videoSidebarButtonMessage}>
          <IconButton
            disableRipple
            className={classes.iconButton}
            size="large"
            aria-label="Like"
            onClick={(e) => {
              e.stopPropagation();
              handleLike();
            }}
          >
            <FavoriteBorderIcon className={classes.iconEventsBlocker} />
          </IconButton>
        </Box>
      )}
      {currentElement.countLike > 0 && (
        <Box sx={{ paddingRight: "15px" }}>{currentElement.countLike}</Box>
      )}
      {!currentElement.isPlanif && (
        <Box className={classes.videoSidebarButtonMessage}>
          <IconButton
            disableRipple
            className={classes.iconButton}
            size="large"
            aria-label="Like"
            onClick={(e) => {
              e.stopPropagation();
              handleMessage();
            }}
          >
            <MessageIcon className={classes.iconEventsBlocker} />
          </IconButton>
        </Box>
      )}
      {currentElement.type !== "message" && currentElement.message && (
        <Box className={classes.videoSidebarButtonMessage}>
          {currentElement.type === "message" ? null : !messageRead ? (
            <IconButton
              disableRipple
              className={classes.iconButton}
              aria-label="Like"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(openModal("comment"));
                dispatch(messageHasBeenRead(currentIndex));
              }}
            >
              <Badge badgeContent={1} color="secondary" overlap="circular">
                <TextsmsIcon className={classes.iconEventsBlocker} />
              </Badge>
            </IconButton>
          ) : (
            <IconButton
              disableRipple
              className={classes.iconButton}
              aria-label="Like"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(openModal("comment"));
                dispatch(messageHasBeenRead(currentIndex));
              }}
            >
              <TextsmsIcon className={classes.iconEventsBlocker} />
            </IconButton>
          )}
        </Box>
      )}
      {currentElement.type === "model" && (
        <>
          {/* {currentElement?.externalLinks?.length > 0 && (
            <Box className={classes.videoSidebarButtonMessage}>
              {isAnyRapidAccessToFalse(currentElement) && (
                <>
                  <Drawer
                    anchor="bottom"
                    open={toggleDrawer}
                    onClose={() => setToggleDrawer(false)}
                  >
                    <CustomIconsList config={currentElement} />
                  </Drawer>
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => setToggleDrawer(true)}
                    size="large"
                  >
                    <LaunchIcon className={classes.iconEventsBlocker} />
                  </IconButton>
                </>
              )}
              {currentElement.model3D.externalLinks.map((item, index) => {
                if (item.rapidAccess) {
                  return (
                    <Box
                      className={classes.customIconButtonContainer}
                      key={`watermark-custom-icons-rapid-access-${index + 1}`}
                    >
                      <Link href={item.link} target="_blank" color={"primary"}>
                        <img
                          alt="Rapid Access Icon"
                          src={item.logo}
                          className={classes.customIconRapidAccess}
                        />
                      </Link>
                    </Box>
                  );
                } else {
                  return null;
                }
              })}
            </Box>
          )} */}
          {currentElement.model3D.isWebXrAI && (
            <Box className={classes.videoSidebarButtonMessage}>
              <IconButton
                className={classes.iconButton}
                style={{
                  marginTop:
                    currentElement?.externalLinks?.length > 0 ? 0 : "0.5rem",
                  marginBottom:
                    currentElement?.externalLinks?.length > 0 ? "0.5rem" : 0,
                }}
                onClick={() => setAIDialogOpen(true)}
                size="large"
              >
                <Assistant className={classes.iconEventsBlocker} />
              </IconButton>
            </Box>
          )}
          <Box
            id="redirectionsBar"
            className={classes.videoSidebarButtonMessage}
          >
            <IconButton
              className={classes.iconButton}
              onClick={() => shareMedia()}
              size="large"
            >
              <ShareIcon className={classes.iconEventsBlocker} />
            </IconButton>
          </Box>
          {currentElement?.description?.initial && (
            <Box
              id="redirectionsBar"
              className={classes.videoSidebarButtonMessage}
            >
              <IconButton
                className={classes.iconButton}
                onClick={() => {
                  setIsDescriptionOpen(true);
                }}
                size="large"
              >
                <InfoIcon className={classes.iconEventsBlocker} />
              </IconButton>
            </Box>
          )}
          <Link
            href={`mailto:${
              currentElement?.contact || "hello@artdesignstory.fr"
            }?subject=${currentElement?.name || ""}`}
            target="_blank"
            rel="noreferrer"
            className={classes.videoSidebarButtonMessage}
          >
            <IconButton className={classes.iconButton} size="large">
              <HelpIcon className={classes.iconEventsBlocker} />
            </IconButton>
          </Link>
        </>
      )}
    </SideBarContainer>
  );
};

export default SideBar;
